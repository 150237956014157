<template>
  <div>
    <div class="fixed-top-action">
      <el-row type="flex" align="middle">
        <el-col :span="12" class="pl20">
          <div class="status-text">Unsaved changes</div>
        </el-col>
        <el-col :span="12" class="text-right pr20">
          <el-button type="default" @click="confirmLeaveForm = true"
            >Болих</el-button
          >
          <el-button type="success" @click="saveTransfer('transferForm')"
            >Хадгалах</el-button
          >
        </el-col>
      </el-row>
    </div>
    <el-row v-if="!pageStatus.error">
      <el-col :span="20" :offset="2">
        <!-- Бүх хуудсанд ашиглагдах header section -->
        <header class="page-header">
          <el-row :gutter="20" align="middle" type="flex">
            <el-col :span="12">
              <router-link to="/return" class="mr20">
                <el-button type="default"
                  ><i class="el-icon-back"></i
                ></el-button>
              </router-link>
              <h2 class="inline-block">{{transferForm.transfer_id}}</h2>
              <el-tag type="warning ml20" effect="dark">{{transferForm.transfer_status}}</el-tag>
            </el-col>
          </el-row>
        </header>
        <!-- form error template -->
        <div class="form-error-container mb20" v-if="errorArray.length != 0">
          <header>Уучлаарай {{errorArray.length}} зүйл алдаатай байна</header>
          <ul class="error-list">
            <div v-for="(error, index) in errorArray" :key="index">
              <li>{{error.value}}</li>
            </div>
          </ul>
        </div>
        <!-- Transfer form -->
        <el-form label-position="top" :model="transferForm" ref="transferForm" v-loading="loading">
          <el-row :gutter="20">
            <el-col :span="16">
              <div class="panel">
                <div class="panel-item">
                  <div class="pb20">
                    <el-button type="default" class="block" @click="showProductsModal(transferForm.supplier_id)">Бүтээгдэхүүн сонгох</el-button>
                  </div>
                </div>
                <div class="panel-item">
                  <ul class="transfer-product-list">
                    <div v-for="(product) in transferForm.products" :key="product.productId">
                      <li v-if="product.variants.length > 0">
                        <el-row :gutter="10" type="flex" align="middle">
                          <el-col :span="2">
                            <div class="image-holder">
                              <img :src="product.product_image" alt="">
                            </div>
                          </el-col>
                          <el-col :span="20">
                            {{product.product_name}}
                          </el-col>
                          <el-col :span="2" class="text-right">
                            <i @click="deleteProductFromTransfer(product)" class="el-icon-close cursor-pointer"></i>
                          </el-col>
                        </el-row>
                        <ul>
                          <li v-for="(variant) in product.variants" :key="variant.variant_id">
                            <el-row :gutter="20" type="flex" align="middle">
                              <el-col :span="16" :offset="2">
                                <div>
                                  {{variant.variant_name}}
                                </div>
                                <div class="text-color-secondary">
                                  {{variant.variant_sku}}
                                </div>
                              </el-col>
                              <el-col :span="6">
                                <div class="text-center">
                                  <el-form-item :class="errorArray.filter(item => item.key === variant.variant_id).length > 0 ? 'is-error': ''">
                                    <el-input
                                      type="number"
                                      min="0"
                                      v-model="variant.number_tobe_transferred"
                                    />
                                  </el-form-item>
                                </div>
                              </el-col>
                              <el-col :span="2" class="text-right">
                                <i @click="deleteVariantFromTransfer(product, variant)" class="el-icon-close cursor-pointer"></i>
                              </el-col>
                            </el-row>
                          </li>
                        </ul>
                      </li>
                    </div>
                  </ul>
                </div>
                 <div class="panel-item">
                  <el-row :gutter="20">
                    <el-col :span="18" :offset="6">
                      <div class="text-right">
                        <el-button type="default" @click="visibleFinishTransfer = true">Буцаалтыг дуусгах</el-button>
                        <el-button type="success" @click="$router.push({name: 'receiveReturnDetail', params: { transfer_id: transferForm.transfer_id }})">Барааг буцаах</el-button>
                      </div>
                    </el-col>
                  </el-row>
                </div>
              </div>
            </el-col>
            <!-- right sidebar -->
            <el-col :span="8">
              <div class="panel">
                <div class="panel-item">
                  <header>Агуулах</header>
                  <div>
                    <div>
                      <strong class="uppercase">{{
                        transferForm.warehouse_name
                      }}</strong>
                    </div>
                  </div>
                </div>
              </div>
              <div class="panel">
                <div class="panel-item">
                  <header>Нийлүүлэгч байгууллага</header>
                  <div v-if="transferForm.supplier">
                    <div>
                      <strong class="uppercase">{{
                        transferForm.supplier.supplier_monName
                      }}</strong>
                    </div>
                    <p>
                      {{transferForm.supplier.address}}
                    </p>
                    <p>{{ transferForm.supplier.phone }}</p>
                    <p>info@goyo.mn</p>
                  </div>
                </div>
              </div>
              <div class="panel">
                <div class="panel-item">
                  <header>Хүлээн авах огноо</header>
                  <el-form-item
                  label="Огноо">
                    <el-date-picker
                      value-format="yyyy-MM-dd HH:mm:ss"
                      v-model="transferForm.expected_arrival_at"
                      type="datetime"
                      placeholder="YYYY-MM-DD"
                      format="yyyy-MM-dd"
                    >
                    </el-date-picker>
                  </el-form-item>
                </div>
              </div>
            </el-col>
          </el-row>
            <div class="border-top pt20">
            <el-row :gutter="20">
              <el-col :span="12">
                <el-button type="danger" plain @click="confirmDeleteDialog = true">Устгах</el-button>
              </el-col>
              <el-col :span="12" class="text-right">
                <el-button type="default" @click="confirmLeaveForm = true"
                  >Болих</el-button
                >
                <el-button type="success" @click="saveTransfer('transferForm')">Хадгалах</el-button>
              </el-col>
            </el-row>
          </div>
        </el-form>
      </el-col>
    </el-row>
    <div v-else class="text-center mtb50 text-color-secondary">
      {{pageStatus.message}}
    </div>
    <!-- Product search -->
    <el-dialog
      title="Бүтээгдэхүүн сонгох"
      :visible.sync="visibleProductDialog"
      width="500"
    >
      <div>
        <el-input
          placeholder="Барааны нэр, SKU ..."
          v-model="searchText"
        >
          <template slot="prepend"><i class="el-icon-search"></i></template>
        </el-input>
      </div>
      <div class="mt20" v-loading="productLoading">
        <ul class="transfer-product-list" v-if="supplierProducts.length > 0">
          <li v-for="product in supplierProducts.filter(data => !searchText || data.productId.toString().toLowerCase().includes(searchText.toString().toLowerCase()) || data.name.toString().toLowerCase().includes(searchText.toString().toLowerCase()))" :key="product.productId">
            <el-row :gutter="10" type="flex" align="middle">
              <el-col :span="2">
                <div class="image-holder">
                  <img :src="product.product_image" alt="">
                </div>
              </el-col>
              <el-col :span="20">
                {{product.product_name}}
              </el-col>
              <el-col :span="2" class="text-right">
                <el-checkbox v-model="product.checked" :indeterminate="product.isIndeterminate" @change="checkProduct(product)"></el-checkbox>
              </el-col>
            </el-row>
            <ul>
              <li v-for="variant in product.variants" :key="variant.variant_id">
                <el-row :gutter="20" type="flex" align="middle">
                  <el-col :span="18" :offset="2">
                    <div>
                      {{variant.variant_name}}
                    </div>
                    <div class="text-color-secondary">
                      {{variant.variant_sku}}
                    </div>
                  </el-col>
                  <el-col :span="2">
                    <div class="text-center">
                      {{variant.availableCount}}
                    </div>
                  </el-col>
                  <el-col :span="2" class="text-right">
                    <el-checkbox :disabled="variant.is_added ? true : false" v-model="variant.checked" @change="checkVariant(product, variant)"></el-checkbox>
                  </el-col>
                </el-row>
              </li>
            </ul>
          </li>
        </ul>
        <div v-else class="text-center mtb30">
          <div class="text-color-placeholder">
            <p>
              Бараа байхгүй байна
              <router-link to="/add-product">
                бүтээгдэхүүн нэмэх
              </router-link>
            </p>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="visibleProductDialog = false">Cancel</el-button>
        <el-button type="success" @click="setTransferProducts">
          Add transfer
        </el-button>
      </span>
    </el-dialog>
    <!-- Confirm discard changes dialog -->
    <el-dialog
      title="Discard all unsaved changes"
      :visible.sync="confirmLeaveForm"
      width="50%"
    >
      <span
        >If you discard changes, you’ll delete any edits you made since you last
        saved.</span
      >
      <span slot="footer" class="dialog-footer">
        <el-button @click="confirmLeaveForm = false" class="mr10"
          >Continue editing</el-button
        >
        <router-link to="/return">
          <el-button type="danger" @click="confirmLeaveForm = false"
            >Discard changes</el-button
          >
        </router-link>
      </span>
    </el-dialog>
    <!-- Delete confirmation -->
    <el-dialog
      title="Буцаалтыг устгах"
      :visible.sync="confirmDeleteDialog"
      width="50%"
    >
      <div>
        <strong>{{transferForm.transfer_id}}</strong> дугаартай буцаалтыг усгахдаа итгэлтэй байна уу?
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="confirmDeleteDialog = false" class="mr10"
          >Болих</el-button
        >
        <el-button type="danger" @click="deleteTransfer(transferForm.transfer_id)">
          Устгах
        </el-button>
      </span>
    </el-dialog>
    <!-- Finish transfer dialog -->
    <finish-dialog
      v-if="visibleFinishTransfer"
      :visible-finish-transfer.sync="visibleFinishTransfer"
      :transfer="transferForm">
    </finish-dialog>
  </div>
</template>
<script>
import services from '../../../helpers/services'
import FinishDialog from './components/finishDialog.vue'
import { getUserName } from '../../../utils/auth.js'
export default {
  name: 'editTransfer',
  components: {
    FinishDialog
  },
  data () {
    return {
      visibleFinishTransfer: false,
      confirmDeleteDialog: false,
      errorArray: [],
      searchText: '',
      confirmLeaveForm: false,
      visibleProductDialog: false,
      supplierProducts: [],
      loading: true,
      productLoading: false,
      isFormFetched: false,
      pageStatus: {
        error: false,
        message: ''
      },
      transferForm: {
        products: [],
        warehouse_name: '',
        expected_arrival_at: null,
        supplier_id: null,
        created_at: null,
        transfer_id: null,
        transfer_name: '',
        changes: [],
        transfer_status: '',
        supplier: {}
      }
    }
  },
  created () {
    if (this.$route.params.transfer_id) {
      this.getTransfer(this.$route.params.transfer_id)
    }
  },
  methods: {
    alertReporter (title, message, type) {
      this.$notify({
        title: title,
        message: message,
        type: type,
        position: 'top-right'
      })
    },
    getTransfer (id) {
      services.getOneReturn(id).then(response => {
        if (response.status === 'success') {
          this.transferForm = response.data
          this.loading = false
        } else if (response.status === 'error') {
          this.pageStatus.error = true
          this.pageStatus.message = response.message
        }
      })
    },
    showProductsModal () {
      if (this.supplierProducts.length > 0) {
        this.visibleProductDialog = true
      } else {
        this.getProducts()
        this.visibleProductDialog = true
      }
    },
    getProducts () {
      this.productLoading = true
      services.getUncheckedTransferProducts(this.transferForm.transfer_id).then(response => {
      // product check, variants check property add
        response.forEach(product => {
          const property = {
            checked: false,
            isIndeterminate: false
          }
          Object.assign(product, property, product)
          product.variants.forEach(variant => {
            const property = {
              checked: variant.is_added,
              number_tobe_transferred: 0
            }
            Object.assign(variant, property, variant)
            this.checkVariant(product, variant)
          })
        })
        this.supplierProducts = response
        this.productLoading = false
      })
    },
    checkProduct (product) {
      product.variants.forEach(variant => {
        if (!variant.is_added) {
          variant.checked = product.checked
        }
      })
    },
    checkVariant (product, variant) {
      let checkedCount = 0
      product.variants.forEach(variant => {
        if (variant.checked) {
          checkedCount = checkedCount + 1
        }
      })
      product.checked = checkedCount === product.variants.length
      product.isIndeterminate = checkedCount > 0 && checkedCount < product.variants.length
    },
    setTransferProducts () {
      const tempProducts = []
      // const tempVariants = []
      // const tempProducts = []
      this.supplierProducts.forEach(product => {
        const variants = [...product.variants.filter(variant => variant.checked)]
        if (variants.length > 0) {
          variants.forEach(variant => {
            this.transferForm.products.forEach(tproduct => {
              tproduct.variants.forEach(tvariant => {
                if (tvariant.variant_id === variant.variant_id) {
                  variant.number_tobe_transferred = tvariant.number_tobe_transferred
                }
              })
            })
          })
          product.variants = variants
          tempProducts.push(product)
        }
      })
      if (tempProducts.length > 0) {
        this.transferForm.products = tempProducts
        this.visibleProductDialog = false
      } else {
        this.$notify.error({
          title: 'Oops',
          position: 'top-right',
          message: 'Бүтээгдэхүүн сонгоно уу',
          duration: 2000
        })
      }
    },
    deleteVariantFromTransfer (product, delVariant) {
      product.variants = product.variants.filter(variant => variant !== delVariant)
    },
    deleteProductFromTransfer (delProduct) {
      this.transferForm.products = this.transferForm.products.filter(product => product !== delProduct)
    },
    async saveTransfer () {
      const sendBody = {
        products: [],
        warehouse_name: this.transferForm.warehouse_name,
        expected_arrival_at: this.transferForm.expected_arrival_at,
        supplier_id: this.transferForm.supplier_id,
        transfer_id: this.transferForm.transfer_id,
        transfer_name: this.transferForm.transfer_name,
        transfer_status: this.transferForm.transfer_status,
        user_name: ''
      }
      sendBody.user_name = await getUserName()
      const productsBody = []
      const variantsBody = []
      this.errorArray = []
      if (this.transferForm.products.length === 0) {
        this.errorArray.push(
          {
            key: '',
            value: 'Бүтээгдэхүүн сонгоно уу'
          }
        )
      }
      this.transferForm.products.forEach(product => {
        product.variants.forEach(variant => {
          if (variant.number_tobe_transferred < 1) {
            this.errorArray.push(
              {
                key: variant.variant_id,
                value: `${variant.variant_sku} дугаартай барааны татан авах ширхэг 1 ээс их байх ёстой`
              }
            )
          }
        })
      })
      if (this.errorArray.length === 0) {
        this.loading = true
        this.transferForm.products.forEach(product => {
          product.variants.forEach(variant => {
            variantsBody.push({
              product_id: product.productId,
              number_tobe_transferred: variant.number_tobe_transferred,
              variant_id: variant.variant_id,
              variant_sku: variant.variant_sku
            })
            productsBody.push({
              productId: product.productId,
              variants: variantsBody
            })
          })
        })
        sendBody.products = productsBody
        services.changeReturn(sendBody).then(data => {
          if (data.status === 'success') {
            this.loading = false
            this.alertReporter('Амжилттай', data.message, 'success')
            this.$router.push({ name: 'return' })
          } else if (data.status === 'error') {
            this.alertReporter('Алдаа', data.message, 'error')
            this.loading = false
          }
        })
      }
    },
    deleteTransfer (id) {
      this.confirmDeleteDialog = false
      this.loading = true
      services.deleteReturn(id).then(response => {
        if (response.status === 'success') {
          this.alertReporter('Амжилттай', `${id} дугаартай буцаалт устлаа`, 'success')
          this.loading = false
          this.$router.push({
            name: 'return'
          })
        } else if (response.status === 'error') {
          this.alertReporter('Алдаа', `${id} дугаартай буцаалтыг устгахад алдаа гарлаа`, 'error')
          this.loading = false
        }
      })
    }
  }
}
</script>
<style>
.el-input-group__prepend {
  background-color: #ffffff;
}
.el-table::before {
  content: unset;
}
.el-table tr:last-child td {
  border-bottom: none;
}
</style>
