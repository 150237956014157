<template>
  <el-popover placement="top" width="auto" trigger="click">
    <div class="text-center">
      <span class="bullet-text success mr10">{{ (accepted) }} accepted</span>
      <span class="bullet-text warning mr10">{{ (canceled) }} canceled</span>
      <span class="bullet-text danger">{{ (rejected) }} rejected</span>
    </div>
    <div slot="reference">
      <div class="progress-bar">
        <span class="success-bar item" :style="'width:' + (accepted / total) * 100 + '%'"></span>
        <span class="warning-bar item" :style="'width:' + (canceled / total) * 100 + '%'"></span>
        <span class="danger-bar item" :style="'width:' + (rejected / total) * 100 + '%'"></span>
      </div>
      <div class="text-right">
        <span class="">
          {{ parseInt(parseInt(accepted) + parseInt(canceled) + parseInt(rejected)) }} of {{ total }}
        </span>
      </div>
    </div>
  </el-popover>
</template>
<script>
export default {
  name: 'transferProgressBar',
  props: [
    'accepted',
    'canceled',
    'rejected',
    'total'
  ]
}
</script>
